<template>
  <div class="wrapper_loading modal__bg">
    <img src="../../assets/images-new/image-chip.svg" alt="logo" class="loading__logo">
    <span class="loading__span">{{ $t('forgotpassword.txt_cargando_dat') }}</span>
  </div>
</template>

<script>
export default {
  name: "LoadingBigComponent",
}
</script>

<style scoped>

.wrapper_loading {
  background: #ffffffCC;
  z-index: 2;
}

.loading__logo {
  display: block;
  height: 80px;
  animation: scale 2s linear infinite;
}

.loading__span {
  font-family: var(--headings-font-family);
  font-weight: var(--bold);
  font-size: 32px;
  margin-top: 15px;
  margin-left: 0;
}

@media only screen and (min-width: 768px) {
  .wrapper_loading {
    flex-direction: row;
  }

  .loading__logo {
    height: 120px;
  }

  .loading__span {
    margin-top: 0;
    margin-left: 40px;
  }
}
</style>