<template>
  <article class="carta__card" style="background-image: url({{category.img}})" data-aos="fade-up">
    <div class="carta__nombre">
      <h3>{{category.name}}</h3>
    </div>
    <div class="carta__hover">
      <h3>{{category.name}}</h3>
      <ButtonSecundary>{{ $t('buttons.txt_ordena_ahora') }}</ButtonSecundary>
    </div>
  </article>
</template>

<script>
import ButtonSecundary from "@/components/new/buttons/ButtonSecundary.vue";

export default {
  name: 'CategoriasCarta',
  components: {ButtonSecundary},
  props: ['category'],
}

</script>

<style scoped>
  .carta__card{
    position: relative;
    display: flex;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 150px;
    border-radius: 8px;
    cursor: pointer;
    overflow: hidden;
  }

  .carta__card h3{
    color: var(--text-white);
    font-family: var(--headings-font-family);
    font-size: 22px;
  }

  .carta__nombre{
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    width: 95%;
    height: 50px;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 10px 20px;
    box-shadow: inset 0 0 400px rgba(150, 64, 21,.95);
    opacity: 1;
    transition: opacity .3s linear
  }

  .carta__nombre h3{
    text-align: left;
    font-family: var(--headings-font-family);
    font-size: 22px
  }

  .carta__hover{
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    transform: translateY(80%);
    width: 90%;
    height: 90%;
    border-radius: 4px;
    opacity: 0;
    box-shadow: inset 0 0 400px var(--primary);
    transition: transform .3s linear
  }

  .carta__card:hover{
    flex-direction: column-reverse;
    align-items: center;
  }

  .carta__card:hover .carta__nombre{
    top: -100%;
    opacity: 0;
  }

  .carta__card:hover .carta__hover{
    opacity: 1;
    transform: translateY(0);
  }

  @media only screen and (min-width: 390px){
    .carta__card{
      height: 180px;
    }
  }

  @media only screen and (min-width: 425px){
    .carta__card{
      height: 250px;
    }
  }

  @media only screen and (min-width: 768px){
    .carta__card{ width: 48%; }
  }

  @media only screen and (min-width: 1024px){
    .carta__card{ width: 425px; }
    .carta__card h3{ font-size: 26px; }
    .carta__nombre h3{ font-size: 26px }
  }
</style>