<template>
  <div class="select__component__body__wrapper">
    <div class="dropdown">
      <label class="input-label"
             :style="{background : `linear-gradient(180deg, ${bg_color} 0%, ${bg_color} 45%, white 45.1%, white 100%)`}" :class="{ 'activated': modelValue != null }">
        {{ placeholder }}
      </label>
      <button @click="toggleDisplayList"
              type="button" class="list_btn"
              :class="display_list ? 'list_btn-active' : ''"
      >
        {{txt_in_filter_btn()}}
        <svg
            class="dropdown__svg"
            :class="display_list? 'dropdown__svg__active': ''"
            width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 14.9499C11.8667 14.9499 11.7417 14.9291 11.625 14.8874C11.5084 14.8457 11.4 14.7749 11.3 14.6749L6.70005 10.0749C6.51672 9.89157 6.42505 9.65824 6.42505 9.3749C6.42505 9.09157 6.51672 8.85824 6.70005 8.6749C6.88338 8.49157 7.11672 8.3999 7.40005 8.3999C7.68338 8.3999 7.91672 8.49157 8.10005 8.6749L12 12.5749L15.9 8.6749C16.0834 8.49157 16.3167 8.3999 16.6 8.3999C16.8834 8.3999 17.1167 8.49157 17.3 8.6749C17.4834 8.85824 17.575 9.09157 17.575 9.3749C17.575 9.65824 17.4834 9.89157 17.3 10.0749L12.7 14.6749C12.6 14.7749 12.4917 14.8457 12.375 14.8874C12.2584 14.9291 12.1334 14.9499 12 14.9499Z" fill="currentColor"/>
        </svg>
      </button>
      <div
          :class="display_list? 'select__component__dropdown__content__active': ''"
          class="select__component__dropdown__content"
      >
        <div
            class="item_lista"
            v-if="!required"
        >
          <input
              type="radio"
              style="display: none"
              :name="name"
              :id="`select_filter_chips__${name}__extra`"
              :value="null"
              :checked="null === modelValue"
              @click="toggleRadio(null)"
          >
          <label class="label_local_item" :for="`select_filter_chips__${name}__extra`">
            Ninguno
          </label>
        </div>
        <div
            class="item_lista"
            v-for="(item, index) in optionsSelect"
            :key="`selectComponent__${name}__${index}`"
        >
          <input
              type="radio"
              style="display: none"
              :name="name"
              :id="`select_filter_chips__${name}__${index}`"
              :value="item"
              :checked="modelValue === item"
              @click="toggleRadio(index)"
          >

          <label class="label_local_item" :for="`select_filter_chips__${name}__${index}`" v-if="valueObj !== null">
            {{ item[valueObj] }}
          </label>

          <label class="label_local_item" :for="`select_filter_chips__${name}__${index}`" v-if="valueObj == null">
            {{ item }}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, ref} from "vue";
import {cloneDeep} from "lodash";

export default defineComponent({
  name: "ChipMobileComponent",

  emits: ['update:modelValue', 'dataFiltered', ],

  props: ['optionsSelect', 'modelValue', 'name', 'valueObj', 'required', 'placeholder', 'bg_color'],

  setup(props, { emit }) {
    const display_list = ref(false);
    const toggleRadio = (index) => {
      let selectedValue
      if (index == null) {
        emit("update:modelValue", null);
        emit('dataFiltered')
        display_list.value = false;
        return
      }
      selectedValue = cloneDeep(props.optionsSelect[index]);

      if (selectedValue === props.modelValue) {
        emit("update:modelValue", selectedValue);
        display_list.value = false;
        return
      }
      emit("update:modelValue", selectedValue);
      emit('dataFiltered')
      display_list.value = false;
    };

    function toggleDisplayList() {
      display_list.value = !display_list.value;
    }

    function txt_in_filter_btn() {
      if (props.modelValue === null) {
        if (props.placeholder) {
          return props.placeholder
        }
        return 'Ninguno'
      }
      if (props.valueObj) {
        return props.modelValue[props.valueObj]
      }
      return props.modelValue

    }

    return { toggleRadio, txt_in_filter_btn, toggleDisplayList, display_list };
  },
})
</script>

<style scoped>
.select__component__dropdown__content{ position: relative; }

.list_btn{
  background-color: var(--complementary-second);
  border: 1px solid var(--complementary-second);
  color: var(--text-black);
}

.list_btn:hover{
  box-shadow: 0 2px 5px rgba(0, 0, 0, .3);
}

.list_btn:active{
  background-color: var(--complementary-second-500);
  border: 1px solid var(--complementary-second-500);
}
</style>